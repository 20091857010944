import { EventBus } from '@/plugins/event-bus'

export const displayError = (ctx, error) => {
  const text = handleError(error)
  const status = error && error.response ? error.response.status : error.status

  if (status >= 400 && status !== 404 && status < 500) {
    EventBus.$emit('snackbar', {
      text,
      color: 'error'
    })
  } else {
    ctx.$nuxt.error({ statusCode: status, message: 'err message' })
  }
}

function handleError(error) {
  let e
  if (error.response && error.response.status) {
    e = error.response
  } else {
    e = error
  }
  let errorMessage
  const defaultError = 'Something gone wrong with your request. Please retry'

  if (e && e.message) {
    errorMessage = e.message
  } else if (e && e.data && typeof e.data === 'string') {
    errorMessage = e.data
  } else if (e && e.data && e.data.errors && e.data.errors.length > 0) {
    errorMessage = e.data.errors[0]
  } else {
    errorMessage = defaultError
  }
  

  if (e && e.status) {
    if (e.status >= 400 && e.status !== 404 && e.status < 500) {
      return errorMessage
    } else {
      return false
    }
  }

  return defaultError
}
