//
//
//
//

import { mapState } from 'vuex'
import speedkitHydrate from 'nuxt-speedkit/hydrate'
import { displayError } from '@/utils/swal'

export default {
  async asyncData({ $axios, params }) {
    const data = await $axios.$get(`/portfolio/${params.id}/seo`)
    const title = `${data.fullName} Photographer`
    const description = `Photography portfolio about ${data.fullName}, community member of 100ASA, a social network for photographers.`
    return { seo: { title, description }}
  },
  data() {
    return {
      loading: true,
    }
  },
  head() {
    return {
      title: this.seo.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.seo.description
        }
      ]
    }
  },
  computed: {
    ...mapState({
      portfolio: (state) => state.portfolio.settings,
    }),
    template() {
      if (this.loading) {
        return speedkitHydrate(() => import('@/components/portfolio/LoadingTemplate.vue'))
      }
      switch (this.portfolio.template) {
        case 1:
          return speedkitHydrate(() => import('@/components/portfolio/TemplateA.vue'))
        default:
          return speedkitHydrate(() => import('@/components/portfolio/LoadingTemplate.vue'))
      }
    },
  },
  async mounted() {
    const portfolioUrl = this.$route.params.id
    if (portfolioUrl) {
      try {
        await this.$store.dispatch('portfolio/getPortfolio', portfolioUrl)
        if (this.portfolio.active) {
          await this.$store.dispatch('portfolio/getUser', this.portfolio.ownerId)
          const album1Data = {
            id: 1,
            page: 1,
            albumId: this.portfolio.albumIds[0],
          }
          await this.$store.dispatch('portfolio/getAlbum', album1Data)
          if (this.portfolio.albumIds.length > 1) {
            const album2Data = {
              id: 2,
              page: 1,
              albumId: this.portfolio.albumIds[1],
            }
            await this.$store.dispatch('portfolio/getAlbum', album2Data)
          }
          if (this.portfolio.albumIds.length > 2) {
            const album3Data = {
              id: 3,
              page: 1,
              albumId: this.portfolio.albumIds[2],
            }
            await this.$store.dispatch('portfolio/getAlbum', album3Data)
          }
          this.$asa.initTemplateColor(this.portfolio)
        } else {
          this.$nuxt.error({ statusCode: 404, message: 'Portfolio not active' })
        }
      } catch (error) {
        displayError(this, error)
      } finally {
        this.loading = false
      }
    }
  }
}
